import React from "react";
import Footer from "src/components/Footer_en";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/en/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/en/our-products/" target="_self">
                      Our Products
                    </a>
                    <ul>
                      <li>
                        <a href="/en/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/ultra_light/">Ultra Light</a>
                      </li>
                      <li>
                        <a href="/en/our-products/protect_cool/">
                          Protect &amp; Cool
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/instantglow/">Instant Glow</a>
                      </li>
                      <li>
                        <a href="/en/our-products/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/en/our-products/wetskin/">Wet skin</a>
                      </li>
                      <li>
                        <a href="/en/our-products/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/en/our-products/aftersun/">After sun</a>
                      </li>
                      <li>
                        <a href="/en/our-products/all_products/">
                          All products
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/en/our-heritage/" target="_self">
                      Our Heritage
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/en/know_the_sun/" target="_self">
                      Know the sun
                    </a>
                  </li>
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      countries
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/legal/private-policy/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/legal/private-policy/">UK</a>
                      </li>
                      <li>
                        <a href="/es/legal/politicia-de-privacidad/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/legal/politica_de_privacidade/">PT</a>
                      </li>
                      <li>
                        <a href="/it/legal/private-policy/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/legal/private-policy/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="index-hero">
              <div id="bgImage">
                <div className="areaInner"></div>
              </div>
            </section>
            <section id="content">
              <div className="innerContent">
                <h2>Privacy Policy</h2>
                <p>
                  <em>Last updated date: 04.05.2023</em>
                </p>
                <p> </p>
                <p>
                  <strong>
                    Johnson &amp; Johnson Limited in the United Kingdom
                  </strong>{" "}
                  is concerned about privacy issues and wants you to be familiar
                  with how we collect, use, and disclose information. This
                  Privacy Policy describes our practices in connection with
                  information that we or our service providers collect through
                  the Web site or Web property (including, for example, a mobile
                  Web site or application) operated and controlled by us from
                  which you are accessing this Privacy Policy (each, the{" "}
                  <strong>"Site"</strong>). By providing personal information to
                  us or by using the Site, you agree to the terms and conditions
                  of this Privacy Policy.
                </p>
                <p>
                  <strong>Note:</strong> The website is owned and published by
                  JNTL Consumer Health I (Switzerland) GmbH, but any personal
                  data, which is collected via the website, is done under the
                  responsibility of Johnson &amp; Johnson Limited in the United
                  Kingdom, who is the data controller for data collection from
                  visitors in the European Economic Area.
                </p>
                <p> </p>
                <h2>INFORMATION COLLECTION</h2>
                <p>
                  <strong>Information You Provide</strong>
                  <br />
                  Some areas of the Site may ask you to submit personal
                  information in order for you to benefit from the specified
                  features (such as newsletter subscriptions, tips/pointers, or
                  order processing) or to participate in a particular activity
                  (such as sweepstakes or other promotions). You will be
                  informed what information is required and what information is
                  optional.
                </p>
                <p>
                  We may combine the information you submit with other
                  information we have collected from you, whether on- or
                  offline, including, for example, your purchase history. We may
                  also combine it with information we receive about you from
                  other sources, such as other Kenvue Operating
                  Companies, publicly available information sources (including
                  information from your publicly available social media
                  profiles), and other third parties.
                </p>
                <p>
                  <strong>Passive Information Collection and Use</strong>
                  <br />
                  As you navigate through a Site, certain information can be
                  passively collected (that is, gathered without your actively
                  providing the information) using various technologies and
                  means, such as Internet Protocol addresses, cookies, Internet
                  tags, and navigational data collection.
                </p>
                <p>
                  Please read the{" "}
                  <a title="Cookie policy" href="/en/legal/cookie-policy/">
                    Cookie Policy
                  </a>
                  , including Annex A for detailed information about cookies and
                  other tracking technologies used on our Site. In this policy
                  you will also find information on how to disable any cookies
                  and tracking technologies if you do not agree with their use.
                  If you do not disable any cookies or other tracking
                  technologies we will infer your consent to their use.
                </p>
                <p>
                  <strong>
                    <br />
                  </strong>
                </p>
                <p>
                  <strong>How We Use and Disclose Information</strong>
                  <br />
                  We use and disclose information you provide to us as described
                  to you at the point of collection.
                </p>
                <p>We also use information from or about you:</p>
                <ul>
                  <li>
                    to respond to your inquiries and fulfill your requests, such
                    as to send you documents you request or e-mail alerts;
                  </li>
                  <li>
                    to send you important information regarding our relationship
                    with you or regarding the Site, changes to our terms,
                    conditions, and policies and/or other administrative
                    information; and
                  </li>
                  <li>
                    for our business purposes, such as data analysis, audits,
                    developing new products, enhancing our website, improving
                    our products and services, identifying Site usage trends,
                    personalizing your experience on the Site by presenting
                    products and offers tailored to you, and determining the
                    effectiveness of our promotional campaigns.
                  </li>
                </ul>
                <p>We also disclose information collected through the Site:</p>
                <ul>
                  <li>
                    to our affiliates for the purposes described in this Privacy
                    Policy. A list of our affiliates is available{" "}
                    <a
                      href="https://investors.kenvue.com/financials-reports/sec-filings/default.aspx"
                      target="_blank"
                    >
                      here
                    </a>{" "}
                    - access Kenvue’s subsequent filings with the Securities Exchange Commission. Johnson &amp; Johnson Limited is the party
                    responsible for the management of the jointly-used Personal
                    Information;
                  </li>
                  <li>
                    to our third party partners with whom we offer a co-branded
                    or co-marketed promotion;
                  </li>
                  <li>
                    to our third party service providers who provide services
                    such as website hosting and moderating, mobile application
                    hosting, data analysis, payment processing, order
                    fulfillment, infrastructure provision, IT services, customer
                    service, e-mail and direct mail delivery services, credit
                    card processing, auditing services, and other services, in
                    order to enable them to provide services; and
                  </li>
                  <li>
                    to a third party in the event of any reorganization, merger,
                    sale, joint venture, assignment, transfer, or other
                    disposition of all or any portion of our business, assets,
                    or stock (including in connection with any bankruptcy or
                    similar proceedings).
                  </li>
                </ul>
                <p>
                  In addition, we use and disclose information collected through
                  the Site as we believe to be necessary or appropriate: (a) as
                  permitted by applicable law, including laws outside your
                  country of residence; (b) to comply with legal process; (c) to
                  respond to requests from public and government authorities,
                  including public and government authorities outside your
                  country of residence; (d) to enforce our terms and conditions;
                  (e) to protect our operations or those of any of our
                  affiliates; (f) to protect our rights, privacy, safety, or
                  property, and/or that of our affiliates, you, or others; and
                  (g) to allow us to pursue available remedies or limit the
                  damages that we may sustain. We may also use and disclose
                  information collected through the Site in other ways, with
                  your consent.
                </p>
                <p>
                  We also use and disclose information we collect passively as
                  described above, under "Passive Information Collection and
                  Use," and for any other purpose, except where we are required
                  to do otherwise under applicable law (for example, if we are
                  required to treat such information as personal information).
                  In addition, we may use and disclose information that is not
                  in personally identifiable form for any purpose. If we combine
                  information that is not in personally identifiable form with
                  information that is (such as combining your name with your
                  geographical location), we will treat the combined information
                  as personal information as long as it is combined.
                </p>
                <p> </p>
                <h2>THIRD PARTY SITES AND SERVICES</h2>
                <p>
                  This Privacy Policy does not address, and we are not
                  responsible for, the privacy, information, or other practices
                  of any third parties, including any third party operating any
                  site or web property (including, without limitation, any
                  application) that is available through this Site or to which
                  this Site contains a link. The availability of, or inclusion
                  of a link to, any such site or property on the Site does not
                  imply endorsement of it by us or by our affiliates.
                </p>
                <p> </p>
                <h2>SECURITY</h2>
                <p>
                  We use reasonable organizational, technical, and
                  administrative measures to protect personal information under
                  our control. Unfortunately, no data transmission over the
                  Internet or data storage system can be guaranteed to be 100%
                  secure. If you have reason to believe that your interaction
                  with us is no longer secure (for example, if you feel that the
                  security of any account you have with us has been
                  compromised), please immediately notify us of the problem by
                  contacting us in accordance with the "Contacting Us" section
                  below.
                </p>
                <p> </p>
                <h2>CHOICES AND ACCESS</h2>
                <p>
                  <strong>
                    Your choices regarding our use and disclosure of your
                    personal information
                  </strong>
                  <br />
                  We give you choices regarding our use and disclosure of your
                  personal information for marketing purposes. You may opt in
                  for:
                </p>
                <ul>
                  <li>
                    <strong>Receiving marketing communications from us:</strong>{" "}
                    If you no longer want to receive marketing communications
                    from us on a going-forward basis, you may opt out of
                    receiving them by contacting us using the information in the
                    Contacting Us section below. In your response to us, please
                    provide your name, identify the form(s) of marketing
                    communications that you no longer wish to receive, and
                    include the address(es) to which it/they are sent. For
                    example, if you no longer wish to receive marketing e-mails
                    or direct mail from us, tell us that, and provide your name
                    and e-mail or postal address.
                    <br />
                    <br />
                  </li>
                  <li>
                    <strong>Receiving reminders from us:</strong> If you no
                    longer want to receive medical reminders from us on a
                    going-forward basis, you may opt out of receiving them by
                    contacting us using the information in the Contacting Us
                    section below. In your response to us, please provide your
                    name and the e-mail address or phone number at which you
                    receive reminders from us.
                    <br />
                    <br />
                  </li>
                  <li>
                    <strong>
                      Our sharing of your personal information with affiliates
                      and third-party partners:
                    </strong>{" "}
                    If you would prefer that we not share your personal
                    information on a going-forward basis with our affiliates
                    and/or third-party partners for their marketing purposes,
                    you may opt out of this sharing by contacting us using the
                    information in the Contacting Us section below. In your
                    response to us, please state that we should no longer share
                    your personal information with our affiliates and/or
                    third-party partners for their marketing purposes, and
                    include your name and e-mail address.
                  </li>
                </ul>
                <p>
                  We will seek to comply with your request(s) as soon as
                  reasonably practicable. Please note that if you opt out as
                  described above, we will not be able to remove your personal
                  information from the databases of our affiliates with which we
                  have already shared your information (i.e., as of the date
                  that we implement your opt-out request). Please also note that
                  if you opt out of receiving marketing-related messages from
                  us, we may still send you important transactional and
                  administrative messages, from which you cannot opt out.
                </p>
                <p> </p>
                <p>
                  <strong>
                    How you can access, change, or delete your personal
                    information
                  </strong>
                  <br />
                  If you would like to review, correct, update, or delete the
                  personal information that you have provided via the Site,
                  please contact us using the information in the Contacting Us
                  section below. We will try to comply with your request as soon
                  as reasonably practicable.
                  <br />
                  <span
                    style={{
                      "font-size": "1.5em"
                    }}
                  >
                    {" "}
                  </span>
                </p>
                <h2>RETENTION PERIOD</h2>
                <p>
                  We retain your personal information for the period necessary
                  to fulfill the purposes outlined in this Privacy Policy,
                  unless a longer retention period is required or allowed by law
                  or to otherwise fulfill a legal obligation.
                </p>
                <h2> </h2>
                <h2>USE OF SITE BY MINORS</h2>
                <p>
                  The Site is not directed to individuals under the age of 18
                  years, and we request that these individuals not provide
                  Personal Information through the Site.
                </p>
                <p> </p>
                <h2>CROSS-BORDER TRANSFER</h2>
                <p>
                  Your personal information may be stored and processed in any
                  country where we have facilities or service providers, and by
                  using our Site or by providing consent to us (where required
                  by law), you agree to the transfer of information to countries
                  outside of your country of residence, including to the United
                  States, which may provide for different data protection rules
                  than in your country.
                </p>
                <p> </p>
                <h2>SENSITIVE INFORMATION</h2>
                <p>
                  Unless we specifically request or invite it, we ask that you
                  not send us, and you not disclose, any sensitive personal
                  information (e.g., information related to racial or ethnic
                  origin, political opinions, religion or other beliefs, health,
                  criminal background or trade union membership) on or through
                  the Site or otherwise to us. In those cases where we may
                  request or invite you to provide sensitive information, we
                  will do so with your express consent.
                </p>
                <p> </p>
                <h2>UPDATES TO THIS PRIVACY POLICY</h2>
                <p>
                  We may change this Privacy Policy. Please take a look at the
                  "LAST UPDATED" legend at the top of this page to see when this
                  Privacy Policy was last revised. Any changes to this Privacy
                  Policy will become effective when we post the revised Privacy
                  Policy on the Site. Your use of the Site following these
                  changes means that you accept the revised Privacy Policy.
                </p>
                <p> </p>
                <h2>CONTACTING US</h2>
                <p>
                  If you have any questions, comments, or concerns specifically
                  about this Privacy Policy or the information practices of this
                  site, please contact us as follows:
                </p>
                <p>Users from the European Economic Area</p>
                <p>
                  <strong>Johnson &amp; Johnson Limited</strong>, <br />
                  50 - 100 Holmers Farm Way
                  <br />
                  High Wycombe
                  <br />
                  HP12 4EG
                  <br />
                  United Kingdom
                  <br />
                  Company No. 02175750 <br />
                  By Phone: On 01344 864 000 between 9.30am-5pm Monday-Friday
                  <br />
                  By <a target="_blank" href="https://globalconsumercare.com/ContactUs/s/ContactForm?language=en_US&id=a296N000000Bsi2">email</a>
                </p>
                <p>
                  If you believe Johnson &amp; Johnson Limited and / or its
                  subsidiaries have not used your data in accordance with the
                  stated Privacy Policy and the company has not resolved the
                  matter in a fair way, you have the right to contact the UK
                  Information Commissioner's Office via the helpline at +44 303
                  123 1113
                </p>
              </div>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
        <script
          dangerouslySetInnerHTML={{
            __html:
              '\n$(document).ready(function(){\nvar i = $("#bgImage img").attr(\'src\');\n$("#bgImage").css("background-image","url("+i+")");\n$("#bgImage img").remove();\n});\n'
          }}
        />
      </div>
    );
  }
}

export default Page;
